import { ALL_OVERALL_PROFIT_LOSS } from "../../constant/ActionType";


export default function AllOvrallPandLossReduc(state = {
    allprofitandlossoverall: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case ALL_OVERALL_PROFIT_LOSS:
            return {
                ...state,
                allprofitandlossoverall: action.data,
                statuscode: action.status
            };

        default:
    }

    return state;
}