import {EXPORT_ALL_PAYSLIP} from "../../constant/ActionType";

export default function PayslipsExportReducer(state = {
    payslipsexportsss:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case EXPORT_ALL_PAYSLIP:
			return { ...state,
				payslipsexportsss: action.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
