import { lazy } from "react";

// project-imports
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import AllChartOfAccount from "pages/ChartOfAccount/allChartOfAccount";
import CreateChartOfAccount from "pages/ChartOfAccount/cuChartOfAccount";
import CreatePaymentVoucher from "pages/paymentVoucher/CuPaymentVoucher";
import * as url from "../store/constant/Endpoints";

import CreateTermsAndCondition from "pages/TermsAndConditions/cuTermsAndCondition";
import AllTermsAndCondition from "pages/TermsAndConditions/allTermsAndCondition";
import CreateTemplate from "pages/Templates/cuTemplate";
import AllTemplates from "pages/Templates/allTemplates";
import CreateQuotation from "pages/Quotation/createquotation";
import ViewSingleQuotation from "pages/Quotation/ViewSingleQuotation";
import CreateChallan from "pages/Challan/CreateChallan";
// import ChallanDetails from "pages/Challan/challandetail";
import { AES, enc } from "crypto-js";

const DashboardDefault = Loadable(lazy(() => import("pages/dashboard")));
// render - data display components
const PermissionsAssign = Loadable(
  lazy(() => import("pages/permissions/assign"))
);

const CompanyAll = Loadable(lazy(() => import("pages/company/allcompany")));
const AllInvoice = Loadable(lazy(() => import("pages/invoice/allinvoice")));
const CreateInvoice = Loadable(
  lazy(() => import("pages/invoice/createInvoice"))
);
const PaymntDetails = Loadable(
  lazy(() => import("pages/paymentVoucher/DetailPaymentVchr"))
);

const InvoiceDetails = Loadable(
  lazy(() => import("pages/invoice/invoicedetail"))
);
const AllRoles = Loadable(lazy(() => import("pages/user-management/allroles")));
const AllUsers = Loadable(lazy(() => import("pages/user-management/allusers")));
const AllUsersRegister = Loadable(
  lazy(() => import("pages/user-management/registerUser"))
);

const AllQuotations = Loadable(
  lazy(() => import("pages/Quotation/Viewquotation"))
);
const PaymentCoucherView = Loadable(
  lazy(() => import("pages/paymentVoucher/paymentvoucher"))
);

const CustomCRPaymentVocuher = Loadable(
  lazy(() => import("pages/paymentVoucher/customervoucherCr"))
);
// const CreatePaymentVoucher = Loadable(lazy(() => import('pages/paymentVoucher/CuPaymentVoucher')));

const CreateCompany = Loadable(
  lazy(() => import("pages/company/createeditcompany"))
);

const ExpenseVoucher = Loadable(
  lazy(() => import("pages/paymentVoucher/ExpenseVoucher"))
);

const CreateCustomer = Loadable(
  lazy(() => import("../../src/pages/company/Customer.js"))
);

const ProjectManage = Loadable(
  lazy(() => import("../../src/pages/DomainParameter/ProjectsManage"))
);

const CreateProjectsManage = Loadable(
  lazy(() => import("../../src/pages/DomainParameter/CreateProjectsManage"))
);


const CustomerCreate = Loadable(
  lazy(() => import("../../src/pages/company/CreateCustomer.js"))
);

const AllTaxes = Loadable(lazy(() => import("pages/Taxes/allTaxes")));
const CreateTaxes = Loadable(lazy(() => import("pages/Taxes/cuTaxes")));
// const Modals = Loadable(lazy(() => import("../companymodal")));

const AllChallan = Loadable(lazy(() => import("pages/Challan/Challan")));
const ChallanDetails = Loadable(
  lazy(() => import("pages/Challan/challandetail"))
);
const AllRecuringEmail = Loadable(
  lazy(() => import("pages/recuringcrud/allrecuringemail"))
);
const CreaterecuringEmail = Loadable(
  lazy(() => import("pages/recuringcrud/curecuringemail"))
);

const AllReports = Loadable(lazy(() => import("pages/report/Allreports")));


const ProjectWiseProfitandLoss = Loadable(lazy(() => import("pages/report/ProjectWiseProfitandLoss")));

const CustomerEarningsAnalysis = Loadable(lazy(() => import("pages/report/CustomerEarningsAnalysis")));

const ExpenseSummaryReport = Loadable(lazy(() => import("pages/report/ExpenseSummaryReport")));

const OverallProfitandLossStatement = Loadable(lazy(() => import("pages/report/OverallProfitandLossStatement")));
const CompanyWiseProfitandLossOverview = Loadable(lazy(() => import("pages/report/CompanyWiseProfitandLossOverview")));



const ReportsbyClients = Loadable(
  lazy(() => import("pages/report/ReportsbyClients"))
);
const ReportsbyCompany = Loadable(
  lazy(() => import("pages/report/ReportsbyCompany"))
);
const Ledger = Loadable(lazy(() => import("pages/report/Ledger.js")));
const PdfPage = Loadable(lazy(() => import("pages/pdf/pdfpage")));

// ---------------------------------------------HRM MODULE---------------------------------------------------------
const EmployeeInfo = Loadable(
  lazy(() => import("pages/hrmmodules/EmployeeInfoModule"))
);
const EmployeeInfoCreate = Loadable(
  lazy(() => import("pages/hrmmodules/EmpInfoCreate"))
);
// const EmployeeInfoView = Loadable(
//   lazy(() => import("pages/hrmmodules/ViewEmployees"))
// );
const PayrollManagement = Loadable(
  lazy(() => import("pages/hrmmodules/PayrollManagement"))
);
const PayrollManCreate = Loadable(
  lazy(() => import("pages/hrmmodules/PayrollCreate"))
);

const AllPayslip = Loadable(lazy(() => import("pages/hrmmodules/AllPayslip")));

// ---------------------------------------------HRM MODULE---------------------------------------------------------

// ---------------------------------------------Leads Management MODULE---------------------------------------------------------
const AllLeaves = Loadable(lazy(() => import("pages/leave/AllLeaves")));
const LeaveManagementCr = Loadable(
  lazy(() => import("pages/leave/LeaveManagementCr"))
);

const BranchesComponent = Loadable(
  lazy(() => import("pages/addleavedesignationbranch/branch"))
);
const DesignationsComponent = Loadable(
  lazy(() => import("pages/addleavedesignationbranch/designation"))
);
const LeaveTypeComponent = Loadable(
  lazy(() => import("pages/addleavedesignationbranch/leavetype"))
);

// const CompanyPolicy = Loadable(
//   lazy(() => import("pages/addleavedesignationbranch/CompanyPolicy"))
// );
const CompanyPolicyCr = Loadable(
  lazy(() => import("pages/addleavedesignationbranch/CompanyPolicyCr"))
);

const AddRosters = Loadable(lazy(() => import("pages/roster/AddRosters")));

const AllLoans = Loadable(lazy(() => import("pages/loan/AllLoans")));
const CreateLoans = Loadable(lazy(() => import("pages/loan/CreateLoans")));

const LoanReturn = Loadable(lazy(() => import("pages/loan/LoanReturn")));

const AttendanceManagement = Loadable(
  lazy(() => import("pages/attendance/AttendanceMang"))
);

const Allowance = Loadable(
  lazy(() => import("pages/DomainParameter/Allowance"))
);
const Deduction = Loadable(
  lazy(() => import("pages/DomainParameter/Deduction"))
);

const Currency = Loadable(lazy(() => import("pages/DomainParameter/Currency")));

// const CreateDeduction = Loadable(
//   lazy(() => import("pages/DomainParameter/CreateDeduction"))
// );

// const CreateAllowance = Loadable(
//   lazy(() => import("pages/DomainParameter/CreateAllowance"))
// );

// ---------------------------------------------Leads Management MODULE---------------------------------------------------------

// render - utils components page
// const Color = Loadable(lazy(() => import('pages/components-overview/color')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/shadows')));

// pages routing

// ==============================|| MAIN ROUTES ||============================== //
let permissionUser = localStorage.getItem("logindata");
let decryptedData = null;
if (permissionUser) {
  decryptedData = AES.decrypt(permissionUser, url.encryptionSecret).toString(
    enc.Utf8
  );
}

const permissions = JSON.parse(decryptedData);
const allowedPermissions =
  permissions && permissions.length !== 0
    ? permissions.map((permission) => permission?.permission)
    : [];
console.log("yes2", allowedPermissions);
const permissionsAccessFunction = (brand) => {
  return (
    allowedPermissions &&
    allowedPermissions.length !== 0 &&
    allowedPermissions.some((permission) => permission.includes(brand))
  );
};

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <DashboardDefault />,
        },
        {
          path: "dashboard",
          element: <DashboardDefault />,
        },
        {
          path: "*",
          element: <DashboardDefault />,
        },
        {
          path: "pdf",
          element: <PdfPage />,
        },
      ],
    },
    {
      path: "domain-parameter",
      element: <MainLayout />,
      children: [
        true
          ? {
            path: "all-company",
            element: <CompanyAll />,
          }
          : null,
        true
          ? {
            path: "all-customer",
            element: <CreateCustomer />,
          }
          : null,
        true
          ? {
            path: "create-customer",
            element: <CustomerCreate />,
          }
          : null,

        true
          ? {
            path: "all-taxes",
            element: <AllTaxes />,
          }
          : null,
        true
          ? {
            path: "chart-of-account",
            element: <AllChartOfAccount />,
          }
          : null,
        true
          ? {
            path: "terms-and-conditions",
            element: <AllTermsAndCondition />,
          }
          : null,
        true
          ? {
            path: "all-templates",
            element: <AllTemplates />,
          }
          : null,
        true
          ? {
            path: "payment-voucher",
            element: <PaymentCoucherView />,
          }
          : null,
          true
          ? {
            path: "customer-payment-voucher",
            element: <CustomCRPaymentVocuher />,
          }
          : null,
        true
          ? {
            path: "expense-voucher",
            element: <ExpenseVoucher />,
          }
          : null,
        // : null,
        // allowedPermissions.includes('register-company') || allowedPermissions.includes('edit-company')

        true
          ? {
            path: "create-company",
            element: <CreateCompany />,
          }
          : null,

        true
          ? {
            path: "create-tax",
            element: <CreateTaxes />,
          }
          : null,
        true
          ? {
            path: "create-account",
            element: <CreateChartOfAccount />,
          }
          : null,
        true
          ? {
            path: "create-terms-and-condition",
            element: <CreateTermsAndCondition />,
          }
          : null,
        true
          ? {
            path: "create-template",
            element: <CreateTemplate />,
          }
          : null,
        true
          ? {
            path: "create-payment-voucher",
            element: <CreatePaymentVoucher />,
          }
          : null,
        {
          path: "payment-voucher-details",
          element: <PaymntDetails />,
        },
        {
          path: "leave-type",
          element: <LeaveTypeComponent />,
        },
        {
          path: "designation",
          element: <DesignationsComponent />,
        },
        {
          path: "branch",
          element: <BranchesComponent />,
        },
        // {
        //   path: "company-policy",
        //   element: <CompanyPolicy />,
        // },
        {
          path: "company-policy-create",
          element: <CompanyPolicyCr />,
        },
        {
          path: "payroll-allowance",
          element: <Allowance />,
        },
        {
          path: "payroll-deduction",
          element: <Deduction />,
        },
        {
          path: "currency",
          element: <Currency />,
        },
        {
          path: "projects-management",
          element: <ProjectManage />,
        },
        {
          path: "projects-management-create",
          element: <CreateProjectsManage />,
        },
        // {
        //   path: "payroll-allowance-create",
        //   element: <CreateAllowance />,
        // },
      ].filter(Boolean),
    },
    permissionsAccessFunction("role")
      ? {
        path: "roles",
        element: <MainLayout />,
        children: [
          true
            ? {
              path: "all-roles",
              element: <AllRoles />,
            }
            : null,
        ].filter(Boolean),
      }
      : null,

    true
      ? {
        path: "hrm-modules",
        element: <MainLayout />,
        children: [
          true
            ? {
              path: "employee-info",
              element: <EmployeeInfo />,
            }
            : null,
          true
            ? {
              path: "emp-info-create",
              element: <EmployeeInfoCreate />,
            }
            : null,
          true
            ? {
              path: "payroll-management",
              element: <PayrollManagement />,
            }
            : null,
          true
            ? {
              path: "payroll-create",
              element: <PayrollManCreate />,
            }
            : null,
          true
            ? {
              path: "payroll-payslip",
              element: <AllPayslip />,
            }
            : null,
        ].filter(Boolean),
      }
      : null,

    true
      ? {
        path: "attendance-management",
        element: <MainLayout />,
        children: [
          true
            ? {
              path: "all-attendance",
              element: <AttendanceManagement />,
            }
            : null,
        ].filter(Boolean),
      }
      : null,

    true
      ? {
        path: "Loan-management",
        element: <MainLayout />,
        children: [
          true
            ? {
              path: "all-loans",
              element: <AllLoans />,
            }
            : null,
          true
            ? {
              path: "loans-create",
              element: <CreateLoans />,
            }
            : null,
          true
            ? {
              path: "loans-return",
              element: <LoanReturn />,
            }
            : null,
        ].filter(Boolean),
      }
      : null,

    true
      ? {
        path: "roster-management",
        element: <MainLayout />,
        children: [
          true
            ? {
              path: "add-leaves",
              element: <AddRosters />,
            }
            : null,
        ].filter(Boolean),
      }
      : null,

    true
      ? {
        path: "leave-management",
        element: <MainLayout />,
        children: [
          true
            ? {
              path: "all-leaves",
              element: <AllLeaves />,
            }
            : null,
          true
            ? {
              path: "leave-management-create",
              element: <LeaveManagementCr />,
            }
            : null,
        ].filter(Boolean),
      }
      : null,

    // : null
    permissionsAccessFunction("company")
      ? {
        path: "quotation",
        element: <MainLayout />,
        children: [
          true
            ? {
              path: "all-quotations",
              element: <AllQuotations />,
            }
            : null,

          // allowedPermissions.includes('register-company') || allowedPermissions.includes('edit-company')
          true
            ? {
              path: "create-quotation",
              element: <CreateQuotation />,
            }
            : null,
          true
            ? {
              path: "view-quotation/:id",
              element: <ViewSingleQuotation />,
            }
            : null,
          true
            ? {
              path: "quotation-details",
              element: <CreateCompany />,
            }
            : null,

          // : null
        ].filter(Boolean),
      }
      : null,

    /////...
    permissionsAccessFunction("company")
      ? {
        path: "recuring",
        element: <MainLayout />,
        children: [
          true
            ? {
              path: "all-recuring-email",
              element: <AllRecuringEmail />,
            }
            : null,

          true
            ? {
              path: "create-recuring-email",
              element: <CreaterecuringEmail />,
            }
            : null,
        ].filter(Boolean),
      }
      : null,

    // permissionsAccessFunction('company')
    // ?
    {
      path: "invoice",
      element: <MainLayout />,
      children: [
        true
          ? {
            path: "all-invoices",
            element: <AllInvoice />,
          }
          : null,

        // allowedPermissions.includes('register-company') || allowedPermissions.includes('edit-company')
        true
          ? {
            path: "create-invoice/:quotation_id",
            element: <CreateInvoice />,
          }
          : null,
        {
          path: "invoice-details",
          element: <InvoiceDetails />,
        },
        // : null
      ].filter(Boolean),
    },
    // : null

    // permissionsAccessFunction('company')
    // ?
    {
      path: "delivery-note",
      element: <MainLayout />,
      children: [
        true
          ? {
            path: "all-challan",
            element: <AllChallan />,
          }
          : null,

        // allowedPermissions.includes('register-company') || allowedPermissions.includes('edit-company')
        true
          ? {
            path: "create-challan",
            element: <CreateChallan />,
          }
          : null,
        {
          path: "challan-details/:id",
          element: <ChallanDetails />,
        },
      ].filter(Boolean),
    },
    // : null

    // permissionsAccessFunction('permission')
    // ?
    {
      path: "permissions",
      element: <MainLayout />,
      children: [
        true
          ? {
            path: "assign",
            element: <PermissionsAssign />,
          }
          : null,
      ].filter(Boolean),
    },

    {
      path: "userManagment",
      element: <MainLayout />,
      children: [
        true
          ? {
            path: "all-users",
            element: <AllUsers />,
          }
          : null,
      ].filter(Boolean),
    },

    {
      path: "reports",
      element: <MainLayout />,
      children: [
        true
          ? {
            path: "all-reports",
            element: <AllReports />,
          }
          : null,
        true
          ? {
            path: "project-profit-loss",
            element: <ProjectWiseProfitandLoss />,
          }
          : null,
        true
          ? {
            path: "customer-earnings",
            element: <CustomerEarningsAnalysis />,
          }
          : null,
        true
          ? {
            path: "expense-report",
            element: <ExpenseSummaryReport />,
          }
          : null,
        true
          ? {
            path: "profit-loss",
            element: <OverallProfitandLossStatement />,
          }
          : null,
        true
          ? {
            path: "company-profit-loss",
            element: <CompanyWiseProfitandLossOverview />,
          }
          : null,  
      ].filter(Boolean),
    },

    {
      path: "reports",
      element: <MainLayout />,
      children: [
        true
          ? {
            path: "reports-by-company",
            element: <ReportsbyCompany />,
          }
          : null,
      ].filter(Boolean),
    },
    {
      path: "reports",
      element: <MainLayout />,
      children: [
        true
          ? {
            path: "ledger",
            element: <Ledger />,
          }
          : null,
      ].filter(Boolean),
    },

    {
      path: "reports",
      element: <MainLayout />,
      children: [
        true
          ? {
            path: "reports-by-clients",
            element: <ReportsbyClients />,
          }
          : null,
      ].filter(Boolean),
    },

    {
      path: "user-management",
      element: <MainLayout />,
      children: [
        true
          ? {
            path: "register-user",
            element: <AllUsersRegister />,
          }
          : null,
      ].filter(Boolean),
    },

    // : null,

    // {
    //   path: 'delivery-note',
    //   element: <MainLayout />,
    //   children: [
    //     // allowedPermissions.includes('get-company')
    //        {
    //           path: 'all-challan',
    //           element: <AllChallan />
    //         },

    //     // allowedPermissions.includes('register-company') || allowedPermissions.includes('edit-company')
    //        {
    //           path: 'create-challan',
    //           element: <CreateChallan />
    //         }

    //       // : null
    //   ].filter(Boolean)
    // },
  ].filter(Boolean),
};

export default MainRoutes;
