// material-ui

import MainCard from "components/MainCard";
import ThemeInput from "components/inputs/ThemeInput";
import { useEffect, useState } from "react";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Spin } from "antd";
import { toast } from "react-toastify";
import { dispatch } from "store/index";
import {
  fetchRegisterTaxxx,
  fetchEditAccounts,
  fetchRegistereacccount,
  fetchCompanyDataa,
  fetchNumberField,
  fetchChartOfAccount,
} from "store/action/index";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Select as SelectAnt } from "antd";
import SearchTextSelect from "components/selects/SearchTextSelect";
// ==============================|| DASHBOARD - DEFAULT ||============================== //

const CreateChartOfAccount = ({ companyData, chartAccount }) => {
  let location = useLocation();

  const [account_name, setAccountName] = useState(
    location.state !== null ? location.state?.account_name : ""
  );
  const [account_type, setAccount_type] = useState(
    location.state !== null ? location.state?.account_type : ""
  );

  const [dr_cr, setdr_cr] = useState(
    location.state !== null ? location.state?.to_increase : ""
  );
  const [company_name, setcompany_name] = useState(
    location.state !== null ? location?.state?.company_id : ""
  );
  const [company_id, setCompanyId] = useState("");

  const [sno, setsno] = useState(
    location.state !== null ? location.state?.s_no : ""
  );
  const [parentsave, setParentSave] = useState(
    location.state !== null ? location.state?.parent_account : ""
  );

  const [account_id, setAccountId] = useState(
    localStorage.getItem("companyName")
  );

  useEffect(() => {
    // Define a function to update the state when localStorage changes
    const updateAccountId = () => {
      setAccountId(localStorage.getItem("companyName"));
    };

    // Attach the function to the 'storage' event
    window.addEventListener("storage", updateAccountId);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("storage", updateAccountId);
    };
  }, []);

  const handleChange = (value) => {
    setParentSave(value);
  };

  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (account_name !== "" && account_type !== "") {
      setLoading(true);

      if (location.state !== null) {
        dispatch(
          fetchEditAccounts({
            id: location.state.id,
            account_name: account_name,
            to_increase: dr_cr,
            account_type: account_type,
            company_id: localStorage.getItem("companyName"),
            parent_account: parentsave,
            // s_no:sno,
            company_id: localStorage.getItem("companyName"),
            setLoading: setLoading,
            setLoading: setLoading,
          })
        );
      } else {
        dispatch(
          fetchRegistereacccount({
            account_name: account_name,
            to_increase: dr_cr,
            account_type: account_type,
            parent_account: parentsave,
            // s_no:sno,
            company_id: localStorage.getItem("companyName"),
            setLoading: setLoading,
          })
        );
      }
    } else {
      toast.error("Required Fields are empty", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        bodyClassName: "toastStyle",
      });
    }
  };

  useEffect(() => {
    dispatch(fetchCompanyDataa());
    dispatch(fetchChartOfAccount());
  }, []);

  const options2 =
    chartAccount && chartAccount.length > 0
      ? [
          { value: null, label: "None" },
          ...chartAccount.map((v) => ({ value: v.id, label: v.account_name })),
        ]
      : [];
  // const handleChangee = (value) => {

  //   setCustomerId(value);
  // };

  const options3 =
    companyData && companyData.length > 0
      ? companyData.map((v) => ({ value: v.id, label: v.company_name }))
      : [];

  const handleChangee = (value) => {
    setAccountId(value);
  };

  return (
    <MainCard>
      {/* <Spin spinning={loading ? true :(companyData?.data && chartAccount?.data ?false:true)}> */}
      <Spin spinning={loading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="row">
              <div className="col-md-4 my-2">
                <ThemeInput
                  id="taxName"
                  value={account_name}
                  onChange={(e) => setAccountName(e.target.value)}
                  type="text"
                  name="Account Name*"
                  placeholder="Enter Account Name"
                />
              </div>

              <div className="col-md-4 my-2">
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="accountType">Account Type*</InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        value={account_type}
                        onChange={(e) => setAccount_type(e.target.value)}
                        id="accountType"
                        name="roleid"
                        input={<OutlinedInput />}
                      >
                        <MenuItem disabled value="">
                          <em>Select Account Type</em>
                        </MenuItem>

                        <MenuItem value={1}>Assets </MenuItem>
                        <MenuItem value={2}>Liabilities</MenuItem>
                        <MenuItem value={3}>Equity</MenuItem>
                        <MenuItem value={4}>Income</MenuItem>
                        <MenuItem value={5}>Expense</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </div>

              <div className="col-md-4 my-2">
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="parentAccount">
                      Parent Account*
                    </InputLabel>
                    {/* <FormControl variant="outlined" fullWidth>
                      <Select
                        value={parentsave}
                        onChange={(e) => setParentSave(e.target.value)}
                        id="parentAccount"
                        name="roleid"
                        input={<OutlinedInput />}
                      >
                        <MenuItem disabled value="">
                          <em>Select Parent Account</em>
                        </MenuItem>

                        {chartAccount &&
                        chartAccount.length !== 0 &&
                        chartAccount.map((v, e) => {
                          return (
                            <MenuItem key={e} value={v?.id}>
                              {v?.account_name}
                            </MenuItem>
                          );
                        })}

                      </Select>
                    </FormControl> */}
                  </Stack>
                </Grid>
                <SelectAnt
                  showSearch
                  style={{
                    width: "100%",
                    height: "54px",
                    marginTop: "8px",
                  }}
                  onChange={handleChange}
                  handleChangeValue={handleChange}
                  tokenSeparators={[","]}
                  options={options2}
                  placeholder="Select Parent Account"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 my-2">
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="company">Debit / Credit*</InputLabel>
                    <FormControl variant="outlined" fullWidth>
                      <Select
                        value={dr_cr}
                        onChange={(e) => setdr_cr(e.target.value)}
                        id="company"
                        name="roleid"
                        input={<OutlinedInput />}
                      >
                        <MenuItem disabled value="">
                          <em>Select Invoice Template</em>
                        </MenuItem>

                        <MenuItem value="Credit">Credit</MenuItem>
                        <MenuItem value="Debit">Debit</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </Grid>
              </div>

              {/* 
              <div className="col-md-4 my-2">
                <ThemeInput
                  onChange={(e) => setsno(e.target.value)}
                  type="number"
                  value={sno}
                  name="Serial Number*"
                  placeholder="Enter Serial Number"
                />
              </div>*/}

              {/* <div className="col-md-4 my-2">
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="companyname">Company Name*</InputLabel>
                  
                  </Stack>
                </Grid>

                <SelectAnt
  showSearch
  style={{
    width: '100%',
    height: '54px',
    marginTop:'8px',
   
  }}
  onChange={handleChangee}
  handleChangeValue={handleChangee}                
  tokenSeparators={[',']}
  options={options3}
  placeholder="Select Company Name"
  filterOption={(input, option) =>
    option.label.toLowerCase().includes(input.toLowerCase())
  }
/>

           
              </div> */}
            </div>
            <div
              className="row my-5"
              style={{
                textAlign: "end",
                display: "flex",
                justifyContent: "flex-end",
                marginRight: 5,
              }}
            >
              <Button
                style={{ width: "auto" }}
                className="px-3"
                onClick={handleSubmit}
                disableElevation
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                {location.state !== null ? "Edit Account" : "Save Account"}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Spin>
    </MainCard>

    //  </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    companyData: state?.company?.companyData?.data,
    chartAccount: state?.chartofaccountt?.chartAccount?.data,
  };
};

export default connect(mapStateToProps)(CreateChartOfAccount);
