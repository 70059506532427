
import { ALL_CUSTOMER_EARNING_REPORTS } from "../../constant/ActionType";


export default function AllCustomerEarningReduc(state = {
    allcustoemrearn: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case ALL_CUSTOMER_EARNING_REPORTS:
            return {
                ...state,
                allcustoemrearn: action.data,
                statuscode: action.status
            };

        default:
    }

    return state;
}