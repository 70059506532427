import { ALL_PROJECT_PROFIT_AND_LOSS } from "../../constant/ActionType";


export default function AllProjPandLossReduc(state = {
    allprofitandlossproj: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case ALL_PROJECT_PROFIT_AND_LOSS:
            return {
                ...state,
                allprofitandlossproj: action.data,
                statuscode: action.status
            };

        default:
    }

    return state;
}