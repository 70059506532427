
import { GET_ALL_PROJECTS } from "../../constant/ActionType";


export default function AllProjectsManageReducer(state = {
    allProjData: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case GET_ALL_PROJECTS:
            return {
                ...state,
                allProjData: action.data,
                statuscode: action.status
            };

        default:
    }

    return state;
}