import {LEAVE_MANAGEMENT_CREATE} from "../../constant/ActionType";

export default function LeavesCrReducer(state = {
    leavesDatacr:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case LEAVE_MANAGEMENT_CREATE:
			return { ...state,
				leavesDatacr: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
