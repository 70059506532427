// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project-imports
import Search from './Search';
import Profile from './Profile';
// import Notification from './Notification';
import MobileSection from './MobileSection';
import RedLogo from "../../../../assets/images/BlueLogo.png"
// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = ({ open }) => {
  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  return (
    <>
      {!downLG && <Search />}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          mt: { xs: 1, lg: 0 },
          // marginRight: "29vw"
        }}
      >
        {/* {open === false ? <img
          src={RedLogo}
          alt="Logo"
          style={{ width: 'auto', height: '60px' }}
        /> : <></>} */}
      </Box>
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
