import { BRANCHES_VIEW_DATAS } from "../../constant/ActionType";

export default function ReducerBranchFle(
  state = {
    branchesdata: [],
    statuscode: null,
  },
  action
) {
  switch (action.type) {
    case BRANCHES_VIEW_DATAS:
      return {
        ...state,
        branchesdata: action.data,
        statuscode: action.status,
      };

    default:
  }

  return state;
}
