import {ALL_EMPLOYEE_EXCEL} from "../../constant/ActionType";

export default function ExcelEmployeeReducers(state = {
    exceldataemplo:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case ALL_EMPLOYEE_EXCEL:
            return { ...state,
                exceldataemplo: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
