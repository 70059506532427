import {GET_COMPANY_POLICY} from "../../constant/ActionType";

export default function PolicyCompanyReducer(state = {
    policyData:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case GET_COMPANY_POLICY:
			return { ...state,
				policyData: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
