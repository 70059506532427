import {ALL_EMPLOYEE_MANAGEMENT} from "../../constant/ActionType";

export default function EmployeeReducer(state = {
    employeeData:[],
    statusCode: null,
}, action) {
    switch (action.type) {    
        case ALL_EMPLOYEE_MANAGEMENT:
			return { ...state,
				employeeData: action?.data ,
                statusCode:action.status
            };

        default:
    }
    return state;
}
