import PropTypes from "prop-types";
import { useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { AppBar, Toolbar, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";

// project-imports
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";

import IconButton from "components/@extended/IconButton";
import { DRAWER_WIDTH } from "config";

// assets
import { HambergerMenu } from "iconsax-react";
import { dispatch } from "../../../store/index";
import { fetchCompanyDataa, fetchDeleteCompany } from "store/action/index";
import { connect } from "react-redux";
import { Select as SelectAnt, Tooltip } from "antd";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle, allcompany }) => {
  const theme = useTheme();
  const downLG = useMediaQuery(theme.breakpoints.down("lg"));
  // header content
  // const headerContent = useMemo(() => <HeaderContent handleDrawerToggle={handleDrawerToggle}/>, []);
  const headerContent = useMemo(() => <HeaderContent open={open} />, [open]);
  const [Filter_data, set_Filter_Data] = useState();
  const [restore_com, set_Restore_Cmp] = useState();
  const [showfielddta, set_Show_Data] = useState();

  const iconBackColorOpen = "secondary.200";
  const iconBackColor = "secondary.100";
  const sicpaMain = "sicpa.main";

  useEffect(() => {
    dispatch(fetchCompanyDataa());
  }, []);

  useEffect(() => {
    set_Restore_Cmp(localStorage.getItem("companyName"));
  }, [localStorage.getItem("companyName")]);

  useEffect(() => {
    if (allcompany) {
      let FilterData = allcompany?.filter((item) => item.company_type == "own");
      set_Filter_Data(FilterData);
    }
  }, [allcompany]);

  useEffect(() => {
    if (allcompany) {
      let FilterData = allcompany?.filter((item) => item.id == restore_com);
      set_Show_Data(FilterData[0]?.company_name);
    }
    console.log("ddddddddceeedd", allcompany);
  }, [restore_com, allcompany]);

  const CurrencyChangeHandle = (value) => {
    localStorage.setItem("companyName", value);
    set_Restore_Cmp((prev) => !prev);
    window.location.reload();
  };

  const currencyOption =
    Filter_data && Filter_data.length > 0
      ? Filter_data?.map((v) => ({ value: v.id, label: v.company_name }))
      : [];

  // common header
  const mainHeader = (
    <Toolbar style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 16px' }}>
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        variant="light"
        size="large"
        style={{
          color: "red",
          backgroundColor: open ? iconBackColorOpen : iconBackColor,
          marginLeft: 0,
          padding: 8,
        }}
      >
        <HambergerMenu />
      </IconButton>
      <div style={{ display: 'flex', alignItems: 'center', gap: '20px', flex: 1, marginLeft: '20px' }}>
        <h1
          style={{
            color: "black",
            margin: 0,
            whiteSpace: 'nowrap',
            fontSize: '24px',
            fontFamily: 'Poppins',
            fontWeight: '800',
          }}
        >
          Hello, Welcome To <span style={{ color: '#ED1D24' }}>Quarter Master</span>
        </h1>
        {/* <img
          src="your-image-url-here"
          alt="Quarter Master Logo"
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
          }}
        /> */}
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginLeft: "10px" }}>
          <Stack>
            <FormControl variant="outlined" fullWidth>
              {showfielddta ? (
                <SelectAnt
                  showSearch
                  style={{
                    width: "200px",
                    height: "40px",
                  }}
                  value={showfielddta ?? ""}
                  onChange={CurrencyChangeHandle}
                  tokenSeparators={[","]}
                  options={currencyOption}
                  placeholder="Select Currency"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              ) : (
                <CircularProgress size={30} />
              )}
            </FormControl>
          </Stack>
        </div>
      </div>
      {headerContent}
    </Toolbar>

  );


  // app-bar params
  const appBar = {
    position: "fixed",
    elevation: 0,
    sx: {
      bgcolor: '#fff !important',
      backdropFilter: "blur(8px)",
      zIndex: 1200,
      width: open ? `calc(100% - ${DRAWER_WIDTH}px)` : "100%",

    },
  };

  return (
    <>
      {!downLG ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    allcompany: state.company.companyData?.data,
  };
};

export default connect(mapStateToProps)(Header);
