import { LEAVETYPE_GT_VIEW } from "../../constant/ActionType";

export default function LeveTTypeRedc(
  state = {
    leavetpdata: [],
    statuscode: null,
  },
  action
) {
  switch (action.type) {
    case LEAVETYPE_GT_VIEW:
      return {
        ...state,
        leavetpdata: action.data,
        statuscode: action.status,
      };

    default:
  }

  return state;
}
