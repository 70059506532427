import { Popconfirm } from "antd";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { RiDeleteBin6Line } from "react-icons/ri";

import { EditOutlined } from "@ant-design/icons";

// let permissionUser = localStorage.getItem('LoginData');
// let permissions = [];

// try {
//   permissions = JSON.parse(permissionUser)?.permissions || [];
// } catch (error) {
//   console.error('Error parsing user permissions:', error);
// }

// const allowedPermissions = permissions.map((permission) => permission?.permission);

const RenderEditButton = (r, p, cb) => {
  return (
    // <Button className="m3-1" type="primary" variant="contained" onClick={() => cb(p, r)}>
    //   <EditOutlined />
    // </Button>

    <IconButton
      style={{
        margin: 0,
        padding: 8,
        width: 40,
        height: 40,
        backgroundColor: '#222831',
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: '4px'
      }}
      onClick={() => cb(p, r)}
    >
      <EditOutlined
        style={{
          color: '#FFFFFF',
          width: 24,
          height: 24
        }}
      />
    </IconButton>
  );
};

const RenderDeactivateButton = (record, cb) => {
  return (
    <Popconfirm
      title={`Are you sure you want to deactivate?`}
      onConfirm={() => cb(record)}
    >
      <IconButton variant="contained">
        <CloseIcon
          style={{
            color: "#FFFFFF",
            width: '40px',
            height: '40px',
            backgroundColor: '#ED1D24',
            borderRadius: '5px',
            padding: '8px',
            marginTop: "4px"
          }}
        />
      </IconButton>
    </Popconfirm>
  );
};

const RenderActivateButton = (record, cb) => {
  return (
    <Popconfirm
      title={`Are you sure you want to Active?`}
      onConfirm={() => cb(record)}
    >
      <IconButton variant="contained">
        <CheckIcon style={{ lineHeight: "6px", border: "none", marginRight: "7px", width: '40px', height: '40px', backgroundColor: '#61C478', borderRadius: '5px', color: '#FFFFFF', padding: '8px',marginTop:'4px' }} />
      </IconButton>
    </Popconfirm>
  );
};

const RenderButton = (link, lable) => {
  return (
    <Link to={link}>
      <Button
        sx={{ margin: "10px" }}
        disableElevation
        size="medium"
        type="submit"
        variant="contained"
        style={{ marginTop: "6px" }}
      // color="primary"
      >
        {lable}
      </Button>
    </Link>
  );
};

export const editButton = (permission, path, record, cb) =>
  // allowedPermissions.includes(permission) ?
  RenderEditButton(record, path, cb); // : null;
export const deactivateButton = (permission, record, cb) =>
  // allowedPermissions.includes(permission) ?
  RenderDeactivateButton(record, cb); // : null;
export const activateButton = (permission, record, cb) =>
  // allowedPermissions.includes(permission) ?
  RenderActivateButton(record, cb); // : null;
export const renderButton = (permission, link, lable) =>
  //  (allowedPermissions.includes(permission) ?
  RenderButton(link, lable); //: null);
