// material-ui

import MainCard from "components/MainCard";
import ThemeInput from "components/inputs/ThemeInput";
import { useEffect, useState } from "react";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
  OutlinedInput,
  Button,
} from "@mui/material";
import { Spin } from "antd";
import { toast } from "react-toastify";
import { dispatch } from "store/index";
import {
  fetchPaymentVocuherr,
  fetchCompanyDataa,
  fetchChartOfAccount,
  fetchCustomer,
  fetchCurrency,
  fetchAllInvoice,
  updatePaymentVocuherr,
  fetchInvoiceDetails,
} from "store/action/index";
import DatePicker from "react-datepicker";

import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Select as SelectAnt } from "antd";
import SearchTextSelect from "components/selects/SearchTextSelect";
// ==============================|| DASHBOARD - DEFAULT ||============================== //

const CreatePaymentVoucher = ({
  companyData,
  chartAccount,
  customerData,
  currencyData,
  invoiceget,
  invoicedetails,
}) => {
  let location = useLocation();

  const [getGrandTotal, setGrandTotal] = useState(0);
  const [getPaidAmount, setPaidAmount] = useState(0);
  const [getPayAbleAmount, setPayAbleAmount] = useState(0);
  const [inputPayAbleAmount, setInputPayAbleAmount] = useState(0);

  const [tax_name, setTax_name] = useState(
    location.state !== null ? location.state?.tax_name : ""
  );
  const [tax_value, setTax_value] = useState(
    location.state !== null ? location.state?.tax_value : ""
  );

  const [tax_type, setTaxType] = useState(
    location.state !== null ? location.state?.tax_type : ""
  );

  const [parentacc, setParentAccount] = useState();
  const [company_id, setCompanyId] = useState(
    localStorage.getItem("companyName")
  );
  const [account_id, setAccountId] = useState("");

  const createdIddd = localStorage.getItem("newIdd");

  const [userObj, setUserObj] = useState({
    user_id: createdIddd,
    company_id: localStorage.getItem("companyName"),
    company_to: location.state?.company_to || location?.state?.customer_id,
  });
  const [loading, setLoading] = useState(false);
  // const [parentaccounnn, setParentAccount] = useState();
  const [companyNameMap, setCompanyNameMap] = useState([]);
  const [chartNameMap, setChartNameMap] = useState([]);
  const [ParentAccountId, setParentAccountId] = useState([]);
  const [customermapdrop, setCustomerMapDrop] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  // const [is_lock, setIsLock] = useState("1");

  useEffect(() => {
    setUserObj((prevUserObj) => ({
      ...prevUserObj,
      company_id: localStorage.getItem("companyName"),
    }));
    setCompanyId(localStorage.getItem("companyName"));
  }, [localStorage.getItem("companyName")]);

  const options3 =
    companyData && companyData.length > 0
      ? companyData.map((v) => ({ value: v.id, label: v.company_name }))
      : [];

  const options2 =
    customerData && customerData.length > 0
      ? customerData.map((v) => ({ value: v.id, label: v.company_name }))
      : [];

  const CutomOptionsss =
    invoiceget && invoiceget?.invoice?.length > 0
      ? [
        { value: null, label: "None" }, // Add a "None" option
        ...invoiceget?.invoice?.map((v) => ({
          value: v.id,
          label: v.invoice_number,
        })),
      ]
      : [];

  const options1 =
    chartAccount && chartAccount.length > 0
      ? chartAccount.map((v) => ({ value: v.id, label: v.account_name }))
      : [];

  const options5 =
    ParentAccountId && ParentAccountId.length > 0
      ? ParentAccountId.map((v) => ({ value: v.id, label: v.account_name }))
      : [];

  const handleChange = (value) => {
    setCompanyId(value); // Assuming you have a state variable like 'customer_id'
    // setUserObj({ ...userObj, company_id: value });
  };

  const InoiceNumHandle = (value) => {
    const inv =
      Array.isArray(invoiceget?.invoice) &&
      invoiceget.invoice.find((v) => v.id === value);

    var grand = inv?.grand_total == null ? 0 : parseFloat(inv?.grand_total);
    var grandTooo = inv?.amount_paid == null ? 0 : parseFloat(inv?.amount_paid);
    var paid =
      inv?.amount_paid == null
        ? 0
        : parseFloat(
          location.state !== null
            ? inv?.amount_paid - location?.state?.amount
            : inv?.amount_paid - 0
        );
    console.log("chyeci", paid);
    var unpaid = grandTooo - paid;
    let amountNew = inv?.grand_total == null ? 0 : parseFloat(inv?.grand_total);
    var grandInvoiceAgainTooo =
      location?.state?.amount_paid !== null
        ? grand - location?.state?.amount_paid
        : null;
    setGrandTotal(grand);
    setPaidAmount(location.state !== null ? unpaid : paid);
    setInputPayAbleAmount(
      location.state !== null
        ? location?.state?.amount_paid
          ? grandInvoiceAgainTooo
          : location?.state?.amount
            ? unpaid
            : grand
        : amountNew - paid
    );
    setUserObj({
      ...userObj,
      inv_ref: value,
      amount:
        location.state !== null
          ? location?.state?.amount_paid
            ? grandInvoiceAgainTooo
            : location?.state?.amount
              ? unpaid
              : grand
          : amountNew - paid,
    });
    console.log("jdjjdd", location?.state?.amount);
  };

  const CurrencyChangeHandle = (value) => {
    setUserObj({ ...userObj, currency: value });
  };

  const handleChangeee = (value, selectedOption) => {
    // Assuming 'value' is the property that holds the actual value in your options
    const selectedValue = selectedOption && selectedOption.value;
    setAccountId(selectedValue);
    console.log("acccount checing", selectedOption);
    setUserObj({
      ...userObj,
      account_id: value,
    });
  };

  const handleeChangeeee = (value) => {
    // setParentAccountId(value); // Assuming you have a state variable like 'customer_id'
    setUserObj({ ...userObj, account_sub_id: value });
  };

  const fetchData = () => {
    dispatch(fetchPaymentVocuherr({ userObj, setLoading: setLoading }));
    setLoading(true);
  };

  const handleCompanyChange = (value) => {
    setSelectedCompany(value);
    setUserObj({ ...userObj, company_to: value });
  };

  useEffect(() => {
    console.log("theswweerrwwwwWwsesee", createdIddd);
  }, [createdIddd]);

  useEffect(() => {
    console.log("theswwwWwsesee", location.state);
  }, [location.state]);

  useEffect(() => {
    let neww = userObj.company_id;

    if (selectedCompany && neww) {
      setLoading(true);
      dispatch(
        fetchAllInvoice({ selectedCompany, neww, setLoading: setLoading })
      );
    }
  }, [selectedCompany, company_id]);

  useEffect(() => {
    if (invoiceget?.invoice?.length !== 0) {
      let value = location?.state?.inv_ref || location?.state?.id;
      const inv =
        Array.isArray(invoiceget?.invoice) &&
        invoiceget.invoice.find((v) => v.id === value);

      var grand = inv?.grand_total == null ? 0 : parseFloat(inv?.grand_total);
      let amountNew =
        inv?.grand_total == null ? 0 : parseFloat(inv?.grand_total);

      var grandTooo =
        inv?.amount_paid == null ? 0 : parseFloat(inv?.amount_paid);

      var paid =
        inv?.amount_paid == null
          ? 0
          : parseFloat(
            location.state !== null
              ? inv?.amount_paid - location?.state?.amount
              : inv?.amount_paid - 0
          );

      var grandInvoiceAgainTooo =
        location?.state?.amount_paid !== null
          ? grand - location?.state?.amount_paid
          : null;

      console.log("chyeci", location?.state?.amount_paid);
      var unpaid = grandTooo - paid;
      setGrandTotal(grand);

      /// paid amount

      setPaidAmount(
        location?.state?.amount_paid
          ? location?.state?.amount_paid
          : location.state !== null
            ? paid
            : paid
      );

      /// Amount

      setInputPayAbleAmount(
        location.state !== null
          ? location?.state?.amount_paid
            ? grandInvoiceAgainTooo
            : location?.state?.amount
              ? unpaid
              : grand
          : // : grand
          amountNew - paid
      );

      setUserObj({
        ...userObj,
        inv_ref: value,
        amount:
          location.state !== null
            ? location?.state?.amount_paid
              ? grandInvoiceAgainTooo
              : location?.state?.amount
                ? unpaid
                : grand
            : amountNew - paid,
      });
      // setIsLock(invoiceget?.invoice);

    }
    console.log("fsfssfs", invoiceget?.invoice)
  }, [location, invoiceget]);

  useEffect(() => {
    // if (chartAccount && chartAccount?.data) {
    //   // let accountID = chartAccount.data.filter(item => item.id == userObj.account_id);

    // }
    let accountID = chartAccount?.filter(
      (item) => item.parent_account == userObj.account_id
    );

    setParentAccountId(accountID);
    console.log("startingg", accountID);
  }, [userObj.account_id, parentacc]);

  const isRequired = true;

  useEffect(() => {
    dispatch(fetchCompanyDataa());
    dispatch(fetchChartOfAccount());
    dispatch(fetchCustomer());
    dispatch(fetchCurrency());
  }, []);

  useEffect(() => {
    if (location.state) {
      console.log("localalalala", location?.state)
      setUserObj({ ...userObj, ...location.state });
      setCompanyId(location.state?.company_id ?? "");
      setSelectedCompany(
        location.state?.company_to || location?.state?.customer_id
      );
    }
    console.log("jjsjsjswwwwsjsj", location?.state?.customer_id);
  }, [location.state]);

  useEffect(() => {
    if (chartAccount && chartAccount.length && location.state) {
      let accountID = chartAccount?.filter(
        (item) => item.parent_account == userObj.account_id
      );
      setParentAccountId(accountID);
    }
  }, [chartAccount]);

  const [currency, setcurrency] = useState(
    location.state !== null ? location.state?.currency : ""
  );

  const currencyOption =
    currencyData && currencyData.length > 0
      ? currencyData.map((v) => ({ value: v.cur_name, label: v.cur_name }))
      : [];

  console.log("currencyOption Testing");
  console.log(currencyOption);

  useEffect(() => {
    dispatch(fetchInvoiceDetails({ setLoading: setLoading }));
  }, []);

  useEffect(() => {
    console.log("chaljaaaaaa121", invoiceget?.invoice);
  }, [invoiceget]);

  // const handelCheckPayableAmount = (e) => {
  //   if (e > getPayAbleAmount) {
  //     alert("Please ");
  //   }
  // };
  const handleSubmit = () => {
    delete userObj.customer_id;
    const datadd = {
      ...userObj,
      company_id: localStorage.getItem("companyName"),
      user_id: createdIddd,
      // is_lock:is_lock,
    };
    console.log("newhitingggg", userObj);
    if (location?.state !== null && !location?.state?.inAgain) {
      setLoading(true);
      dispatch(
        fetchPaymentVocuherr({ userObj: datadd, setLoading: setLoading })
      );
    } else {
      setLoading(true);

      dispatch(
        updatePaymentVocuherr({ userObj: datadd, setLoading: setLoading })
      );
    }
  };

  return (
    <MainCard>
      <Spin spinning={loading}>
        <Grid container spacing={2} sx={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <div className="row">
              {/* <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Company From*
                  </InputLabel>
                </Stack>

                <SelectAnt
                  showSearch
                  style={{
                    width: '100%',
                    height: '54px',
                    marginTop: '8px',

                  }}
                  onChange={handleChange}
                  value={company_id}
                  handleChangeValue={handleChange}
                  tokenSeparators={[',']}
                  options={options3}
                  placeholder="Select Company"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />

              </div> */}
              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Company To*
                  </InputLabel>
                </Stack>
                <SelectAnt
                  showSearch
                  disabled={location.state !== null ? true : false}
                  style={{
                    width: "100%",
                    height: "54px",
                    marginTop: "5px",
                  }}
                  value={selectedCompany ?? ""}
                  onChange={handleCompanyChange}
                  handleChangeValue={handleCompanyChange}
                  tokenSeparators={[","]}
                  options={options2}
                  placeholder="Select Company"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />

                {/* {selectedCompany && (
      <Stack spacing={1}>
        <InputLabel htmlFor="AnotherInput" style={{ fontSize: '13px' }}>
          Another Input*
        </InputLabel>
       
        <input
          type="text"
          className="form-control"
          onChange={handleCompanyChange}
        />
      </Stack>
    )} */}
              </div>

              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Invoice Number*
                  </InputLabel>
                </Stack>
                <SelectAnt
                  showSearch
                  disabled={location.state !== null ? true : false}
                  style={{
                    width: "100%",
                    height: "54px",
                    marginTop: "5px",
                  }}
                  tokenSeparators={[","]}
                  value={userObj?.inv_ref ?? ""}
                  onChange={(e) => InoiceNumHandle(e)}
                  options={CutomOptionsss}
                  placeholder="Invoice Number"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />

                {/* {selectedCompany && (
      <Stack spacing={1}>
        <InputLabel htmlFor="AnotherInput" style={{ fontSize: '13px' }}>
          Another Input*
        </InputLabel>
       
        <input
          type="text"
          className="form-control"
          onChange={handleCompanyChange}
        />
      </Stack>
    )} */}
              </div>

              <div className="col-md-4 my-2">
                <ThemeInput
                  type="text"
                  name="Payment Reference*"
                  placeholder="Payment Reference"
                  value={userObj?.payment_reference ?? ""}
                  onChange={(e) =>
                    setUserObj({
                      ...userObj,
                      payment_reference: e.target.value,
                    })
                  }
                />
              </div>
              <div className="row">
                <div className="col-md-4 my-2">
                  <ThemeInput
                    type="text"
                    name="Grand Total*"
                    placeholder="Grand Total"
                    value={getGrandTotal}
                    disabled
                  />
                </div>
                <div className="col-md-4 my-2">
                  <ThemeInput
                    type="text"
                    name="Total Paid*"
                    placeholder="Total Paid"
                    value={getPaidAmount}
                    disabled
                  />
                </div>
                {/* <div className="col-md-4 my-2">
                  <ThemeInput
                    type="number"
                    name="Payable Amount*"
                    placeholder="Total UnPaid"
                    value={inputPayAbleAmount||getPayAbleAmount}
                    disabled={getPayAbleAmount === 0 ? true : false}
                    max={getPayAbleAmount}
                    onChange={(e) => {
                      setUserObj({ ...userObj, total_unpaid: e.target.value });
                      // setPayAbleAmount(e.target.value);
                      if(Number(e.target.value)<= Number(getPayAbleAmount)){
                        setInputPayAbleAmount(e.target.value)
                      }
                      // handelCheckPayableAmount(e.target.value);
                    }}
                  />
                </div> */}
                <div className="col-md-4 my-2">
                  <ThemeInput
                    type="number"
                    name="Amount*"
                    placeholder="Amount"
                    value={inputPayAbleAmount}
                    onChange={(e) => {
                      setUserObj({ ...userObj, amount: e.target.value });
                      setInputPayAbleAmount(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Currency*
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth></FormControl>
                </Stack>

                <SelectAnt
                  showSearch
                  style={{
                    width: "100%",
                    height: "54px",
                  }}
                  value={userObj?.currency ?? ""}
                  onChange={CurrencyChangeHandle}
                  tokenSeparators={[","]}
                  options={currencyOption}
                  placeholder="Select Currency"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </div>
              <div className="col-md-4 my-2">
                <ThemeInput
                  type="date"
                  name="Date*"
                  value={userObj?.date}
                  onChange={(e) =>
                    setUserObj({ ...userObj, date: e.target.value })
                  }
                />
              </div>

              {/* <div className="col-md-4 my-2">
                <ThemeInput
                  type="text"
                  name="Description*"
                  placeholder="Description"
                  value={userObj?.description ?? ""}
                  onChange={(e) =>
                    setUserObj({ ...userObj, description: e.target.value })
                  }
                />
              </div> */}
              <div className="col-md-4 my-2">
                <ThemeInput
                  type="text"
                  name="Description"
                  placeholder="Description"
                  value={userObj?.description ?? ""}
                  onChange={(e) =>
                    setUserObj({ ...userObj, description: e.target.value })
                  }
                />
              </div>

              {/* <div className="col-md-4 my-2">
                <ThemeInput
                  type="text"
                  name="Currency*"
                  placeholder="Currency"
                  onChange={(e) => setUserObj({ ...userObj, currency: e.target.value })}
                />
              </div>  */}

              <div className="col-md-3 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Account Name*
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      displayEmpty
                      input={<OutlinedInput />}
                      style={{ height: "52px" }}
                      value={
                        userObj?.account_id &&
                          chartAccount &&
                          chartAccount.length
                          ? chartAccount.filter(
                            (v) => v.id == userObj?.account_id
                          )[0]
                          : ""
                      }
                      onChange={(e) => {
                        setUserObj({
                          ...userObj,
                          account_id: e.target.value.id,
                        });
                        setParentAccount(e.target.value.parent_account);
                        console.log("binsnsn", e.target.value.parent_account);
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>Select Account</em>
                      </MenuItem>

                      {chartAccount &&
                        chartAccount.length !== 0 &&
                        chartAccount.map((v, e) => {
                          return (
                            <MenuItem value={v}>{v.account_name}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Stack>
              </div>
              <div className="col-md-3 my-2">
                <Stack spacing={1}></Stack>
                <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                  Sub Account
                </InputLabel>
                <SelectAnt
                  showSearch
                  style={{
                    width: "100%",
                    height: "54px",
                    marginTop: "8px",
                  }}
                  value={userObj?.account_sub_id ? userObj?.account_sub_id : ""}
                  onChange={handleeChangeeee}
                  tokenSeparators={[","]}
                  options={options5}
                  placeholder="Select Company"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                />
              </div>

              <div className="col-md-3 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Payment Type*
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      displayEmpty
                      input={<OutlinedInput />}
                      style={{ height: "52px" }}
                      value={userObj?.payment_type ?? ""}
                      onChange={(e) =>
                        setUserObj({ ...userObj, payment_type: e.target.value })
                      }
                    >
                      <MenuItem disabled value="">
                        <em>Select Payment type</em>
                      </MenuItem>

                      <MenuItem value="InWard">InWard</MenuItem>
                      <MenuItem value="OutWard">OutWard</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </div>
              {/* <div className="col-md-3 my-2">
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    File Lock
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      displayEmpty
                      input={<OutlinedInput />}
                      style={{ height: "52px" }}
                      value={is_lock}
                      onChange={(e) => {
                        setIsLock(e.target.value);
                      }}
                    >
                      <MenuItem disabled value="">
                        <em>Select</em>
                      </MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="5">No</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </div> */}

              <div
                className={`col-md-${userObj?.payment_mode === "Cash" ? 12 : 3
                  } my-2`}
              >
                <Stack spacing={1}>
                  <InputLabel htmlFor="AssignRole" style={{ fontSize: "13px" }}>
                    Payment Method*
                  </InputLabel>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      displayEmpty
                      input={<OutlinedInput />}
                      style={{ height: "52px" }}
                      value={userObj?.payment_mode ?? ""}
                      onChange={(e) =>
                        setUserObj({ ...userObj, payment_mode: e.target.value })
                      }
                    >
                      <MenuItem disabled value="">
                        <em>Select Payment Method</em>
                      </MenuItem>

                      <MenuItem value="Cash">Cash</MenuItem>
                      <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
                      <MenuItem value="Cheque">Cheque</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </div>
              {userObj.payment_mode == "Cash" ? null : (
                <>
                  <div className="col-md-3 my-2 ">
                    <ThemeInput
                      type="text"
                      name="Bank Name*"
                      placeholder="Bank Name"
                      value={userObj?.bank_name ?? ""}
                      onChange={(e) =>
                        setUserObj({ ...userObj, bank_name: e.target.value })
                      }
                    />
                  </div>

                  <div className="col-md-3 my-2">
                    <ThemeInput
                      type="text"
                      name="Sender Name*"
                      placeholder="Sender Name"
                      value={userObj?.sender_name ?? ""}
                      onChange={(e) =>
                        setUserObj({ ...userObj, sender_name: e.target.value })
                      }
                    />
                  </div>

                  <div className="col-md-3 my-2">
                    <ThemeInput
                      type="text"
                      name="Transaction / Cheque Number"
                      placeholder="Transiction Id"
                      value={userObj?.transaction_id ?? ""}
                      onChange={(e) =>
                        setUserObj({
                          ...userObj,
                          transaction_id: e.target.value,
                        })
                      }
                    />
                  </div>
                </>
              )}
            </div>
            <div style={{ textAlign: "end" }} className="my-3">
              <Button
                style={{ width: "auto" }}
                className="px-3"
                onClick={handleSubmit}
                disableElevation
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                {location.state !== null && !location.state?.inAgain
                  ? "Edit Payment Voucher"
                  : "Save Payment Voucher"}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Spin>
    </MainCard>
  );
};

const mapStateToProps = (state) => {
  return {
    companyData: state?.company?.companyData?.data,
    chartAccount: state?.chartofaccountt?.chartAccount?.data,
    customerData: state?.customerdata?.customerData?.data,
    currencyData: state?.currency?.currencyData?.data,
    invoiceget: state?.getinvoiceall?.invoiceget?.data,
    invoicedetails: state.invoicDetailess.invoicedetails,
  };
};

export default connect(mapStateToProps)(CreatePaymentVoucher);
{
  {
  }
}
