
import { ALL_COMPANY_PROFIT_LOSS } from "../../constant/ActionType";


export default function AllCompanyProfitLossReduc(state = {
    allprofitlosscomapny: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case ALL_COMPANY_PROFIT_LOSS:
            return {
                ...state,
                allprofitlosscomapny: action.data,
                statuscode: action.status
            };

        default:
    }

    return state;
}