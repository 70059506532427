// project-imports
// import components from './components';
import dashboard from "./dashboard";
// import permissions from './permissions';
import deliveryNote from "./deliveryNote";
import domainParameter from "./domainParameter";
import paymentVoucher from "./PaymentVoucher";
// import support from './support';
// import pages from './pages';
// import randomcode from './randomcode';
import permissions from "./permissions";
// import subCategories from './subcategory';
import invoice from "./invoice";
import quotation from "./quotation";
import Recuring from "./recuringemail";
import roles from "./roles";
import userManagment from "./userManagment";
import reports from "./reports";
// import reportsbyclients from './reportsbyclients';

// ==============================|| MENU ITEMS ||============================== //
let assignUserChecking = localStorage.getItem("userassigncompany");

const menuItems = {
  items: [
    ...(dashboard && assignUserChecking == "false" ? [dashboard] : []),
    ...(quotation && assignUserChecking == "false" ? [quotation] : []),
    ...(invoice && assignUserChecking == "false" ? [invoice] : []),
    ...(paymentVoucher && assignUserChecking == "false"
      ? [paymentVoucher]
      : []),
    ...(deliveryNote && assignUserChecking == "false" ? [deliveryNote] : []),
   
    // ...(reportsbyclients ? [reportsbyclients] : [])
  ].filter(Boolean),
};

export default menuItems;
