// type
import { Home3, HomeTrendUp } from "iconsax-react";
import { MdOutlineDashboard } from "react-icons/md";

import icons from "../helper/iconsHelper";

// icons
// const icons = {
//   navigation: Home3,
//   // dashboard: HomeTrendUp
//   dashboard: MdOutlineDashboard

// };

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "group-dashboard",
  // title: 'Navigation',
  icon: icons.navigation,
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icons.dashboard,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
