import logo from 'assets/images/logo.png';

const LogoMain = () => {

  return (
    <img src={logo} alt="icon logo" width="250" />

  );
};

export default LogoMain;
