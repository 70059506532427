
import { ALL_EXPENSE_SUMMARY_REPORTS } from "../../constant/ActionType";


export default function AllExpenseSummaryy(state = {
    allexpensereportss: [],
    statuscode: null,
}, action) {
    switch (action.type) {
        case ALL_EXPENSE_SUMMARY_REPORTS:
            return {
                ...state,
                allexpensereportss: action.data,
                statuscode: action.status
            };

        default:
    }

    return state;
}